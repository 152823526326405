<!-- eslint-disable vue/max-attributes-per-line -->
<template>
	<div class="header-outer-wrapper">
		<div class="header-inner-wrapper big">
			<router-link style="margin-left: 0" :to="{ name: 'Home' }">
				<img src="@/assets/images/upgraded/enon-logo-white.svg" alt="" />
			</router-link>
			<div class="spacer"></div>
			<router-link
				:class="{ active: getActiveHome('') }"
				:to="{ name: 'Home' }"
				@click="scrollTop"
			>
				Home
			</router-link>
			<router-link
				:class="{ active: getActiveHome('testi') }"
				:to="{ path: '/' }"
				@click="onScrollTestimonial"
			>
				<span>Testimonials</span>
			</router-link>
			<router-link
				:class="{ active: route.name === 'Projects' }"
				:to="{ name: 'Projects', params: {} }"
			>
				Projects
			</router-link>
			<router-link
				:class="{ active: route.name === 'Technology' }"
				:to="{ name: 'Technology', params: {} }"
			>
				Technologies
			</router-link>
			<!-- <router-link
				:class="{ active: route.name === 'Case Studies' }"
				:to="{ name: 'Case Studies', params: {} }"
			>
				Case Studies
			</router-link> -->
			<router-link
				:class="{ active: route.name === 'TeamPage' }"
				:to="{ name: 'TeamPage', params: {} }"
			>
				Team
			</router-link>
			<router-link
				class="apply-btn"
				:to="{ name: 'QuoteContact' }"
				@click="store.trackQuoteCTA()"
				>Get a free quote</router-link
			>
		</div>
		<div class="header-inner-wrapper small">
			<router-link style="margin-left: 0" :to="{ name: 'Home' }">
				<img src="@/assets/images/upgraded/enon-logo-white.svg" alt="" />
			</router-link>
			<div class="spacer"></div>
			<burger-menu v-model="sidebar" />
			<Teleport to="body">
				<sidebar :show="sidebar" @hide="sidebar = false" />
			</Teleport>
		</div>
	</div>
</template>

<script>
import { howMuchScrolledFromTop } from "../../js/helpers";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useIntersectionObserver } from "@vueuse/core";
import { useDefaultStore } from "@/store";
import scrollTopVue from "../scrollTop.vue";
export default {
	name: "AppHeader",
	setup() {
		const route = useRoute();
		const sidebar = ref(false);
		const isActiveHome = ref("");
		const store = useDefaultStore();
		let instance = null;

		watch(sidebar, () => {
			if (sidebar.value) {
				document.querySelector("body").style.overflow = "hidden";
				window.scrollTo({ top: 0 });
				document
					.getElementsByClassName("header-outer-wrapper")[0]
					.classList.add("solid2");
				store.isOpenChatBox = false;
			} else {
				document.querySelector("body").style.overflow = "visible";
				document
					.getElementsByClassName("header-outer-wrapper")[0]
					.classList.remove("solid2");
			}
		});
		function scrollTop() {
			window.scrollTo({ top: 0, behavior: "smooth" });
		}
		function turnOffSidebar() {
			if (window.innerWidth > 768) {
				sidebar.value = false;
			}
		}
		const getActiveHome = (option) => {
			if (route.name === "Home") {
				return isActiveHome.value === option;
			}
			return false;
		};

		function onScrollTestimonial() {
			setTimeout(() => {
				const el = document.getElementById("testimonial");
				let coorY = 0;
				if (el) {
					coorY = el.offsetTop;
				}
				window.scrollTo({
					top: coorY,
					behavior: "smooth",
				});
			}, 10);
		}

		window.onresize = turnOffSidebar;

		function scrollToBottom() {
			window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
		}

		watchEffect(async () => {
			if (route.name !== "Home") {
				instance?.stop();
			} else {
				await nextTick();
				activateIntObs();
			}
		});

		function activateIntObs() {
			const target = document.getElementById("testimonial");
			instance = useIntersectionObserver(
				target,
				([res], observerElement) => {
					if (res.intersectionRatio >= 0.5) {
						isActiveHome.value = "testi";
					} else {
						isActiveHome.value = "";
					}
				},
				{
					threshold: 0.5,
				},
			);
		}

		addEventListener("scroll", () => {
			if (howMuchScrolledFromTop() > 100) {
				document
					.getElementsByClassName("header-outer-wrapper")[0]
					.classList.add("solid");
			} else {
				document
					.getElementsByClassName("header-outer-wrapper")[0]
					.classList.remove("solid");
			}
		});
		return {
			sidebar,
			route,
			isActiveHome,
			getActiveHome,
			scrollTop,
			scrollToBottom,
			onScrollTestimonial,
		};
	},
};
</script>

<style lang="scss" scoped>
.solid {
	background-color: rgb(13, 16, 21) !important;
}

.solid2 {
	background-color: rgb(13, 16, 21) !important;
}

.header-outer-wrapper {
	height: $top-page-nav-height;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 20px;
	z-index: 4;
	position: fixed;
	width: 100%;
	top: 0;
	transition: background-color 0.5s ease;

	a {
		text-decoration: none;
	}

	.header-inner-wrapper.small {
		display: none;
	}

	.header-inner-wrapper {
		width: 100%;
		max-width: $content-max-width;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		.spacer {
			flex-grow: 1;
		}

		a {
			margin-left: 60px;
			text-decoration: none;
			color: var(--vt-c-white);
			font-weight: 600;
			font-size: 18px;
			transition-duration: 0.3s;
		}

		a.active {
			color: $app-color-light;
		}

		a:hover {
			color: $app-color-light;
		}

		.apply-btn {
			margin-left: 60px;
			background: $app-color;
			border-radius: $button-border-radius;
			height: $button-height;
			width: 160px;
			color: #fff;
			font-weight: 600;
			font-size: 18px;
			line-height: 18px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition-duration: 0.3s;
			white-space: nowrap;
			min-width: 160px;
		}

		.apply-btn:hover {
			opacity: 0.9;
			color: #fff;
		}
	}
}

@media screen and (width <= 768px) {
	.header-outer-wrapper {
		.header-inner-wrapper.big {
			display: none;
		}

		.header-inner-wrapper.small {
			display: flex;
		}
	}
}
</style>
